<template>
  <div>
    <v-app-bar flat >
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click="drawer = true"></v-app-bar-nav-icon>
      <v-container v-if="!$vuetify.breakpoint.mobile">
        <v-row no-gutters>
          <v-col cols="4">
            <v-btn to="/" text>
              <img style="height:30px; margin-right: 10px" alt="Logo der Website Gemeidehaushaus.digital" contain src="../assets/logo-opengov-kreis.png" >{{projectName}}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn to="/termine" text>Termine</v-btn>
          </v-col>
          <v-col>
            <v-btn to="/raeume" text>Räume</v-btn>
          </v-col>
          <v-col>
            <v-btn to="/faq" text>Infos</v-btn>
          </v-col>
          <!--<v-col>
            <v-btn to="/toolbox" text>Werkzeugkoffer</v-btn>
          </v-col>-->
          <v-col>
            <v-btn to="/anregungen-kontakt" text>Kontakt</v-btn>
          </v-col>
          <v-col>
            <router-link to="/leichte-sprache">
              <img src="@/assets/signet-leichte-sprache.png" alt="Logo für Leichte Sprache" class="easy-language"/>
            </router-link>
          </v-col>
          <v-col>
            <v-dialog v-model="formDialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="orange" dark v-bind="attrs" v-on="on">Meine Idee</v-btn>
              </template>
              <IdeaForm :formDialog.sync="formDialog" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="$vuetify.breakpoint.mobile" class="text-center">
        Gemeindehaus.digital
      </v-container>
    </v-app-bar>
  
    <v-navigation-drawer v-model="drawer" app temporary>
        <v-list nav>
          <v-list-item>
            <v-btn class="ma-2" text to="/">Gemeindehaus.digital</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="ma-2" text to="/termine">Termine</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="ma-2" text to="/raeume">Räume</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="ma-2" text to="/faq">Infos</v-btn>
          </v-list-item>
          <!--
          <v-list-item>
            <v-btn class="ma-2" text to="/toolbox">Werkzeugkoffer</v-btn>
          </v-list-item>
          -->
          <v-list-item>
            <v-btn to="/anregungen-kontakt" text>Kontakt</v-btn>
          </v-list-item>
          <v-list-item>
            <v-dialog v-model="formDialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="orange" dark v-bind="attrs" v-on="on">Meine Idee</v-btn>
              </template>
              <IdeaForm :formDialog.sync="formDialog" />
            </v-dialog>
          </v-list-item>
          <v-list-item>
            <router-link to="/leichte-sprache">
              <img src="@/assets/signet-leichte-sprache.png" alt="Logo für Leichte Sprache" class="easy-language"/>
            </router-link>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
  import IdeaForm from '../components/IdeaForm.vue'

  export default {
    name: 'Header',
    components: {
      IdeaForm
    },
    data: () => ({
      formDialog: false,
      drawer: false
    }),
    computed: {
      projectName: function () {
        return process.env.VUE_APP_PROJECT_NAME
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/_variables.scss";

  .easy-language {
    max-width: 44px;
    height: 44px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .easy-language {
      max-width: 30px;
      height: 30px;
    }
  }
</style>