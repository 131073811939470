import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/termine',
    name: 'Termine',
    component: () => import(/* webpackChunkName: "Events" */ '../views/Events.vue')
  },
  {
    path: '/raeume',
    name: 'Rooms',
    component: () => import(/* webpackChunkName: "Rooms" */ '../views/Rooms.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "FAQ" */ '../views/FAQ.vue')
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/anregungen-kontakt',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/Feedback.vue')
  },
  {
    path: '/leichte-sprache',
    name: 'Leichte Sprache',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/EasyLanguage.vue')
  },
  {
    path: '/challenge',
    name: 'Challenge',
    //component: () => import(/* webpackChunkName: "Imprint" */ '../views/Challenge.vue')
    component: Home
  },
  {
    path: '/statement',
    name: 'Statement',
    //component: () => import(/* webpackChunkName: "Imprint" */ '../views/Statement.vue')
    component: Home
  },
  {
    path: '/umfrage',
    name: 'Umfrage',
    component: Home
    //component: () => import(/* webpackChunkName: "Imprint" */ '../views/Survey.vue'),
    /*beforeEnter(to, from, next) {
      window.location.href = "https://limesurvey.gutbefragen.de/limesurvey/142698";
    }*/
  },
  {
    path: '/toolbox',
    name: 'Toolbox',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/Toolbox.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
