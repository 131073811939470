<template>
  <v-app>
    
    <Header />
    
    <v-main>
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
  import Footer from './components/Footer.vue'
  import Header from './components/Header.vue'

  export default {
    components: {
      Footer,
      Header
    },
    data: () => ({ 
      drawer: null
    }),
  }
</script>