<template>

  <div>
    <v-card>
      <v-card-title>Meine Idee</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" style="background-color: white">
          
          <v-text-field label="Vor- und Nachname" v-model="name"></v-text-field>
          <v-text-field label="eMail" v-model="email"></v-text-field>
          <v-text-field label="Telefonnummer" v-model="phone"></v-text-field>

          <v-text-field label="Betreff" v-model="title" required :rules="[v => !!v || 'Angabe wird benötigt']"></v-text-field>
          <v-textarea label="Nachricht" v-model="description" required :rules="[v => !!v || 'Angabe wird benötigt']"></v-textarea>
          
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <v-checkbox v-model="contact_permission">
                <template v-slot:label>
                  <div>
                    Ja, ich möchte wissen, was aus meiner Nachricht geworden ist. Ich willige ein, dass ich für diesen Zweck kontaktiert werden darf und habe meine Kontaktdaten nur zu diesem Zweck hinterlassen. Außerdem stimme ich der Datenschutzerklärung zu.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-checkbox  v-model="utilization_permission">
                <template v-slot:label>
                  <div>
                    Ich willige ein, dass meine hier eingereichte Idee im Rahmen des Projekts „Regionales Open Government Labor Marburg-Biedenkopf“ verwendet werden darf. Ich stelle meine Idee unter der Creative Commons Zero Lizenz zur Verfügung. Weitere Details zur Creative Commons Lizenz finden Sie im <a @click.stop="" href="https://gemeindehaus.digital/impressum" target="_blank">Impressum</a>.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn :disabled="!valid || !sendableForm" color="success" @click="send">Abschicken</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text >
    </v-card>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Vielen Dank für deine Nachricht!
        </v-card-title>
        
        <v-card-text class="pa-5">
          Wir freuen uns auf Ihre Mitteilung und melden uns bei Ihnen, sofern Sie Ihre Kontaktdaten hinterlassen haben und uns Ihr Einverständnis zur Kontaktaufnahme erteilt haben.
          <br /><br />
          Ihr Team „Regionales Open Government Labor Marburg-Biedenkopf“
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'IdeaForm',
       props: {
        formDialog: {
        default: false
      }
    },
    data: () => ({
      dialog: false,
      valid: false,
      name: null,
      email: null,
      phone: null,
      title: null,
      description: null,
      utilization_permission: false,
      contact_permission: false
    }),
    methods: {
      async send () {
       
        if(!this.title || !this.description)  return false

        if (this.contact_permission && (!this.phone && !this.email)) return false

        const ideas = this.$client.items('ideas');

        // save in directus
        await ideas.createOne({
          name: this.name,
          email: this.email,
          phone: this.phone,
          title: this.title,
          contact_permission: this.contact_permission,
          utilization_permission: this.utilization_permission,
          description: this.description
        });

        // clear values
        this.name = null
        this.email = null
        this.phone = null
        this.title = null
        this.description = null
        this.utilization_permission = false
        this.contact_permission = false

        // open confirmation dialog
        this.dialog = true

        // close from
        this.$emit('update:formDialog', false)
      },
    },
    computed: {
      sendableForm: function () {
        if(!this.title || !this.description) return false
        if (this.contact_permission && (!this.phone && !this.email)) return false

        return true
      }
    }
  }
</script>
