<template>
  <div class="HOME" v-if="home">
    <v-container>
      <v-img v-if="home.header_image" class="white--text align-end" height="300px" :src="$ASSET_URL + home.header_image.id + '?width=1920&quality=85'" :alt="home.header_image.title">
      <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.header_image.description}}</span>
    </v-img>
  </v-container>  
    <v-container>
      <h2 style="margin-top: 15px">Willkommen im digitalen Gemeindehaus!</h2>

      <div class="home-box">

        <v-row v-if="home && home.box_1" class="pa-2 my-4">
          <v-col cols="12" :md="home.box_1_image ? 6 : 12" class="pa-2" v-html="home.box_1" />
          <v-col cols="12" md="6" v-if="home.box_1_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_1_image.id" :alt="home.box_1_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_1_image.description}}</span>
            </v-img>
          </v-col>
        </v-row>

        <v-row v-if="home && home.box_2" class="pa-2 my-4" style="background-color: WhiteSmoke">
          <v-col cols="12" md="6" v-if="home.box_2_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_2_image.id" :alt="home.box_2_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_2_image.description}}</span>
            </v-img>
          </v-col>
          <v-col cols="12" :md="home.box_2_image ? 6 : 12" class="pa-2" v-html="home.box_2" />
        </v-row>

        <v-row v-if="home && home.box_3" class="pa-2 my-4">
          <v-col cols="12" :md="home.box_3_image ? 6 : 12" class="pa-2" v-html="home.box_3" />
          <v-col cols="12" md="6" v-if="home.box_3_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_3_image.id" :alt="home.box_3_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_3_image.description}}</span>
            </v-img>
          </v-col>
        </v-row>
        
        <v-row v-if="home && home.box_4" class="pa-2 my-4" style="background-color: WhiteSmoke">
          <v-col cols="12" md="6" v-if="home.box_4_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_4_image.id" :alt="home.box_4_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_4_image.description}}</span>
            </v-img>
          </v-col>
          <v-col cols="12" :md="home.box_4_image ? 6 : 12" class="pa-2" v-html="home.box_4" />
        </v-row>

        <v-row v-if="home && home.box_5" class="pa-2 my-4">
          <v-col cols="12" :md="home.box_5_image ? 6 : 12" class="pa-2" v-html="home.box_5" />
          <v-col cols="12" md="6" v-if="home.box_5_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_5_image.id" :alt="home.box_5_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_5_image.description}}</span>
            </v-img>
          </v-col>
        </v-row>

        <v-row v-if="home && home.box_6" class="pa-2 my-4" style="background-color: WhiteSmoke">
          <v-col cols="12" md="6" v-if="home.box_6_image">
            <v-img max-height="300" contain :src="$ASSET_URL + home.box_6_image.id" :alt="home.box_6_image.title" style="width:80%; margin: auto" class="white--text align-end">
              <span class="text-sm-caption text-md-body-1" style="background-color: rgba(1,1,1,0.3); margin: 0 0 10px 0; padding: 4px">{{home.box_6_image.description}}</span>
            </v-img>
          </v-col>
          <v-col cols="12" :md="home.box_6_image ? 6 : 12" class="pa-2" v-html="home.box_6" />
        </v-row>

      </div>
    </v-container>

  <div class="news">
    <v-container v-if="news">
      <h2>Neuigkeiten aus dem Projekt</h2>

      <v-row>
        <v-col cols="12" v-for="(entry, index) in news" :key="index">
          <v-row>
            <v-col cols="12" order="1">
            <h3 class="mt-2">{{entry.title}}</h3>
            </v-col>
            <v-col cols="12" :md="entry.image ? 6 : 12" :order="(index % 2)+1">
              <div v-html="entry.text" class="mt-2"/>
            </v-col>
            <v-col v-if="entry.image" cols="12" md="6" :order="!(index % 2)+1">
              <v-img :src="$ASSET_URL + entry.image.id" max-height="400px" contain />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
    <!-- <v-container style="background-color: WhiteSmoke">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
      </p>
    </v-container> -->
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    home: null,
    news: null
  }),
  async created() {
    let temp = await this.$client.items("home").readMany({ fields: ['*.*']})
    this.home = temp.data

    temp = await this.$client.items("news").readMany({ fields: ['*.*'], sort: '-date_created'})
    this.news = temp.data
  }

}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

 .home-box {
    margin-top: 40px;
    padding: 5px;
    text-align: justify
  }

  .news {
    text-align: justify
  }
  

</style>